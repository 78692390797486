// import reliabilityIndexTranslates from '../section-project/reliability-index-i18n.json';
import developerTranslates from '../section-developer/developer-i18n';

export default function () {
  return {
    // ...reliabilityIndexTranslates,
    ...developerTranslates(),

    developersTopic: {
      ru: 'Застройщики',
      ua: 'Забудовники'
    },

    // developersFoundedYearText: {
    //   ru: 'Основана в {{placeholder}}\u00a0году',
    //   ua: 'Заснована у {{placeholder}}\u00a0році'
    // },
    developersLinkTitle: {
      ru: 'Смотреть все проекты',
      ua: 'Переглянути всі проєкти'
    },
    developersLinkText: {
      ru: 'Еще',
      ua: 'Ще'
    },

    inProgressSort: {
      ru: 'По строящимся домам',
      ua: 'По будинкам, що будуються'
    },
    readyHouseSort: {
      ru: 'По готовым домам',
      ua: 'По готовим будинкам'
    }
  };
}
