export const configLabels = {
  textFrom: {
    ru: 'от',
    ua: 'від'
  },
  priceBeginText: {
    ru: 'от',
    ua: 'від'
  },
  priceStocksBeginText: {
    ru: 'Цена от',
    ua: 'Ціна від'
  },
  addressLabel: {
    ru: 'Адрес',
    ua: 'Адреса'
  },
  toponymsLabel: {
    ru: 'Район',
    ua: 'Район'
  },
  builtDate: {
    ru: 'Сдача',
    ua: 'Здача'
  },
  allFlatsSold: {
    ru: 'Все квартиры от застройщика проданы',
    ua: 'Всі квартири від забудовника продані'
  },
  allFlatsSoldTile: {
    ru: 'Все квартиры проданы',
    ua: 'Всі квартири продані'
  },
  allFlatsSoldItem: {
    ru: 'К сожалению все квартиры c данной планировкой проданы',
    ua: 'На жаль всі квартири з данним плануванням продані'
  },
  allCommercialSoldTile: {
    ru: 'Все нежилые проданы',
    ua: 'Всі нежитлові продані'
  },
  allCommercialSoldItem: {
    ru: 'К сожалению все нежилые c данной планировкой проданы',
    ua: 'На жаль всі нежитлові з данним плануванням продані'
  },
  allHouseAndAreaTile: {
    ru: 'Все дома и участки проданы',
    ua: 'Всі будинки і ділянки продані'
  },
  allHouseAndAreaItem: {
    ru: 'К сожалению все дома и участки c данной планировкой проданы',
    ua: 'На жаль всі будинки і ділянки з данним плануванням продані'
  },
  allSoldTile: {
    ru: 'Продано',
    ua: 'Продано'
  },
  lastFlats: {
    ru: 'Последние квартиры',
    ua: 'Останні квартири'
  },
  lastCommercial: {
    ru: 'Последние нежилые',
    ua: 'Останні нежитлові'
  },
  lastHouseAndArea: {
    ru: 'Последние дома и участки',
    ua: 'Останні будинки і ділянки'
  },
  salesSuspendedUntil: {
    ru: 'Продажи приостановлены',
    ua: 'Продажі призупинені'
  },
  startOfSales: {
    ru: 'Старт продаж',
    ua: 'Старт продажу'
  },
  startOfSalesSoon: {
    ru: 'Скоро старт продаж',
    ua: 'Скоро старт продажів'
  },
  startOfNewTurnSales: {
    ru: 'Старт продаж новой очереди',
    ua: 'Старт продажів нової черги'
  },
  number_of_storeys: {
    ru: 'Этажность',
    ua: 'Поверховість'
  },
  creators: {
    ru: 'Застройщик',
    ua: 'Забудовник'
  },
  complexSite: {
    ru: 'На сайт ЖК',
    ua: 'На сайт ЖК'
  },
  defaultPriceText: {
    ru: 'от застройщика',
    ua: 'від забудовника'
  },
  fullAddress: {
    ru: 'Адрес',
    ua: 'Адреса',
  },
  subwaysMap: {
    ru: 'Ближайшие станции метро',
    ua: 'Найближчі станції метро',
  },
  landmarksMap: {
    ru: 'Ближайшие ориентиры',
    ua: 'Найближчі орієнтири',
  },
  // complexSites: {
  //   single: 'Сайт ЖК',
  //   plural: 'Сайты ЖК',
  // },
  // webcams: {
  //   single: 'Вебкамера',
  //   plural: 'Вебкамеры',
  // },
};

export default configLabels;
