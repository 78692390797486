/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import get from 'lodash/get';
import map from 'lodash/map';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import { numDescOnlyText } from 'helpers/numDesc';
import beautifyNumber from 'helpers/beautifyNumber';
import Link from 'components/link';
import sensor from 'components/sensor';
import { Thumb } from 'components/image';
import Row, { Col } from 'components/row';
import Slick from 'components/slick-slider';

import linksDomik from 'src-sites/domik/links';

import { getHousesCounter } from '../../section-project/render-common';

import translates from 'src-sites/domik/section-developers/developers-i18n';

import 'styles/domik/blocks/_block-developer.scss';

const bemClasses = new Bem('viewTileDeveloper');
const bemClassesContainer = new Bem('viewTilesContainer');

@I18nHoc(translates())
class DeveloperItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      avatar: PropTypes.shape({
        id: PropTypes.string
      }),
      id: PropTypes.number,
      name: PropTypes.string,
      name_long: PropTypes.string,
      url: PropTypes.string,
      newbuilds_count: PropTypes.number,
    })
  };

  render() {
    const { i18n } = this;
    const { data = {} } = this.props;
    const {
      name_long,
      url,
      // work_start_date,
      living_complex_counters = {},
      rel_finished_cottage_towns,
      avatar, name
    } = data;
    const image = typeof avatar === 'string' ? { id: avatar } : avatar;
    const avatarDeveloper = { ...image, alt: name, title: name };
    const housesCounter = getHousesCounter.call(null, living_complex_counters);
    const minBuiltYear = housesCounter.ready_house_from_year ? i18n('developerYear', { placeholder: housesCounter.ready_house_from_year }) : '';

    return (
      <div {...bemClasses()}>
        <Row {...bemClasses('row')} allColumn>
          <Col {...bemClasses('col', 'img')} allPr={0}>
            <div {...bemClasses('imgContainer')}>
              <div {...bemClasses('img')}>
                <Thumb
                  {...bemClasses('imgPic')}
                  image={avatarDeveloper}
                />
                <JsonLd
                  item={{
                    '@context': 'https://schema.org',
                    '@type': 'ImageObject',
                    name: get(avatarDeveloper, 'alt', null),
                    description: get(avatarDeveloper, 'title', null),
                    contentUrl: get(image, 'id') ? absoluteUrl(`/images/thumb/full/${image.id}`) : null
                  }}
                />
              </div>
            </div>
          </Col>
          <Col {...bemClasses('col', 'info')} allPr={0}>
            <Link
              {...bemClasses('link', 'fill')}
              builder={linksDomik.sectionLanding}
              builderProps={{ title: name_long, url }}
            >
              <span />
            </Link>
            {name &&
              <h3 {...bemClasses('topic')}>
                <span
                  {...bemClasses('text')}
                  children={name}
                />
              </h3>
            }
          </Col>
          <Col {...bemClasses('col', 'numerics')} allPr={0}>
            <div {...bemClasses('item', 'numeric')}>
              <span {...bemClasses('text')}>
                <span {...bemClasses('text', 'numeric')} children={beautifyNumber(housesCounter.in_progress_house_number || 0)} />
                {`\u00a0${numDescOnlyText(
                  housesCounter.in_progress_house_number || 0,
                  i18n('developerHouse'),
                  i18n('developerHouseDeclination'),
                  i18n('developerHouseMultiple')
                )} ${i18n('developerBuild')} ${i18n('developerInCount', { placeholder: housesCounter.in_progress_house_in_lc_number || 0 })}`}
              </span>
            </div>
            <div {...bemClasses('item', 'numeric')}>
              <span {...bemClasses('text')}>
                <span {...bemClasses('text', 'numeric')} children={beautifyNumber(housesCounter.ready_house_number || 0)} />
                {`\u00a0${numDescOnlyText(
                  housesCounter.ready_house_number || 0,
                  i18n('developerHouseReady'),
                  i18n('developerHouseReadyDeclination'),
                  i18n('developerHouseReadyMultiple')
                )} ${i18n('developerInCount', { placeholder: housesCounter.ready_house_in_lc_number || 0 })}${minBuiltYear}`}
              </span>
            </div>
            {+rel_finished_cottage_towns > 0 &&
              <div {...bemClasses('item', 'numeric')}>
                <span {...bemClasses('text')}>
                  <span
                    {...bemClasses('text', 'numeric')}
                    children={beautifyNumber(+rel_finished_cottage_towns || 0)}
                  />
                  {`\u00a0${numDescOnlyText(
                    +rel_finished_cottage_towns || 0,
                    i18n('developerHouseReadyCT'),
                    i18n('developerHouseReadyCTDeclination'),
                    i18n('developerHouseReadyCTMultiple')
                  )}`}
                </span>
              </div>
            }
          </Col>
        </Row>
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'HomeAndConstructionBusiness',
            name,
            url: absoluteUrl(url),
            logo: absoluteUrl(`/images/thumb/full/${image.id}`),
            image: absoluteUrl(`/images/thumb/full/${image.id}`)
          }}
        />
      </div>
    );
  }
}

@sensor
export default class Developers extends Component {
  static propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({})),
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'phone'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  componentDidUpdate() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;

    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  render() {
    const { content } = this.props;

    if (!content || content.length === 0) return null;

    const breakpointDesktop = (this.sensor.getVariable('breakpointDesktop') || 1250) - 1;
    const breakpointTablet = (this.sensor.getVariable('breakpointTablet') || 768) - 1;

    return (
      <div {...bemClassesContainer()}>
        <Slick
          slidesToShow={5}
          initialSlide={0}
          responsive={[{
            breakpoint: breakpointDesktop,
            settings: {
              slidesToShow: 3,
              initialSlide: 3
            }
          }, {
            breakpoint: breakpointTablet,
            settings: {
              slidesToShow: 1
            }
          }]}
        >
          {map(content, (item, key) => {
            return (
              <DeveloperItem
                key={key}
                data={item}
              />
            );
          })}
        </Slick>
      </div>
    );
  }
}
