import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import findIndex from 'lodash/findIndex';
import lowerCase from 'lodash/lowerCase';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import beautifyNumber from 'helpers/beautifyNumber';
import numDesc, { humanizeBigNumbers, numDescOnlyText } from 'helpers/numDesc';
import { FavoritesButton } from 'subsys/favorites';
import Link from 'components/link';
import { Thumb } from 'components/image';
import Row, { Col } from 'components/row';
import { iconTypes } from 'components/icon';
import InteractiveComponent from 'components/interactive-fly-component';

import { ModalCallBack } from 'src-sites/bild/section-project/contact-to-developer';
import { getAskDevHeader } from 'src-sites/bild/section-project/render-common';
import linksBackOffice from 'src-sites/backoffice/links';

import { getAltPrice } from '../render-common';

import translates from '../../section-projects/card-i18n';

const bemClasses = new Bem('viewList');

@I18nHoc(translates())
export default class ListCard extends Component {
  static propTypes = {
    className: PropTypes.string,
    dataMods: PropTypes.shape({
      domik: PropTypes.bool,
      plans: PropTypes.bool,
      // plansGroup: PropTypes.bool
    }),
    object: PropTypes.shape({}),
    projectData: PropTypes.shape({}),
    favData: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
    soldData: PropTypes.shape({}),
    permObjManage: PropTypes.bool
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.listForObjects = this.listForObjects.bind(this);
    this.listForComplex = this.listForComplex.bind(this);
  }

  listForObjects(isSold) {
    const { i18n } = this;
    const {
      object = {},
      projectData = {}
      // , soldData
    } = this.props;
    const {
      images, object_properties = {}, price = {},
      // all_flats_sold_object, flats_count, $entity,
      classesDict, url
    } = object;
    const { all_area, land_area, land_area_type } = object_properties;
    const altPrice = getAltPrice.call(this, price);
    const image = images && images[0] ? images[0].id : null;

    const type = get(object, 'complex.type');
    const dict = get(classesDict, 'dict');
    const apartmentName = classesDict.value_long || classesDict.entity_long;
    const apartmentValue =
      classesDict.combo || classesDict.value_long || classesDict.entity_long || 1;
    const apartmentType = 'ROM';
    const landArea = land_area * 100;
    const areaTxt = all_area || landArea;
    const areaType = 'MTK';

    const typeObject = get(object, 'classesDict.value_long') || get(object, 'type_dict.entity_long') || get(object, 'classes') || '';
    const areaLand = get(object, 'object_properties.land_area') && numDesc(get(object, 'object_properties.land_area'), get(object, 'object_properties.land_area_type.F1'), get(object, 'object_properties.land_area_type.F2'), get(object, 'object_properties.land_area_type.F3'));
    const areaPerMeter = `${get(object, 'object_properties.all_area')} м²`;
    const areaObject = areaLand || areaPerMeter;
    const header = get(object, 'complex_display_name') || get(object, 'house_properties.complex_name');
    const pricePerMeterFunc = renderPricePerMeter(price, bemClasses, true);
    const pricePerMeter = pricePerMeterFunc ? `${i18n('textFrom')} ${pricePerMeterFunc}` : i18n('defaultPriceText');
    const locality = get(projectData, 'locality_display_name') || get(object, 'location_path');
    const alt = `${typeObject} ${areaObject} в ${header} ${pricePerMeter}, ${locality}`;
    const flatsConversion = get(this, 'props.projectData.product.addition_fields.flats_conversion');
    const developerEmail = get(projectData, 'salers[0].contact_email');
    const askDevHeader = getAskDevHeader.call(this, bemClasses, projectData);
    const discount = get(object, 'possible_discount');
    const modalBtnLabel = discount ? i18n('aboutDiscount') : i18n('aboutAvailability');
    const modalBtnIcon = discount ? iconTypes.gift : iconTypes.phoneCalls;

    return (
      <>
        {this.renderThumb(image, alt)}
        <Col allPr={0} {...bemClasses('col', 'between')}>
          <Row {...bemClasses('row', 'between')} alignIcenter>
            {this.renderArea({ all_area, land_area, land_area_type })}
            {renderMainPrice.call(this, price, bemClasses, false, isSold)}
            {this.renderAltPrice(altPrice)}
            {/*
            {this.renderSoldOrCount(soldData, all_flats_sold_object, flats_count, $entity)}
            */}
          </Row>
        </Col>
        {this.renderFavouriteButton()}
        {flatsConversion && developerEmail &&
          <Col {...bemClasses('col', 'btnCallBack')} mdAuto mtAuto allPr={0}>
            <ModalCallBack
              classes={bemClasses}
              askDevHeader={askDevHeader}
              projectId={projectData.id}
              projectUrl={url}
              developer={projectData.main_developer}
              developerEmail={developerEmail}
              modalBtnProps={{
                label: modalBtnLabel,
                title: modalBtnLabel,
                inListDD: false,
                iconType: modalBtnIcon
              }}
            />
          </Col>
        }
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'ImageObject',
            name: alt || null,
            description: alt || null,
            contentLocation: get(projectData, 'parent_display_path', null),
            contentUrl: image ? absoluteUrl(`/images/thumb/full/${image}`) : null
          }}
        />
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'Apartment',
            name: apartmentName,
            url: absoluteUrl(url),
            image: absoluteUrl(`/images/thumb/full/${image}`),
            numberOfRooms: (type === 'living_complex' && dict === 'rooms') ? {
              '@type': 'QuantitativeValue',
              value: apartmentValue,
              unitCode: apartmentType
            } : null,
            floorSize: {
              '@type': 'QuantitativeValue',
              // maxValue: maxArea,
              // minValue: minArea,
              value: areaTxt,
              unitCode: areaType
            }
          }}
        />
      </>
    );
  }

  listForComplex(isSold) {
    const { object = {}, soldData, projectData = {} } = this.props;
    const { data = {}, url } = object;
    const {
      image, sort,
      value = {}, minArea, maxArea, all_area, land_area
    } = data;

    if (!sort || sort <= 0) return null;

    const type = get(this, 'props.type');
    const groupId = get(object, 'data.groupId');
    // const dict = get(value, 'dict');
    const apartmentName = value.value_long || value.entity_long;
    const apartmentValue = value.combo || value.value_long || value.entity_long || 1;
    const apartmentType = 'ROM';
    const landArea = land_area * 100;
    const areaTxt = minArea || all_area || landArea;
    const areaType = 'MTK';

    const typeObject = get(data, 'value.multiple_l') || get(data, 'value.value') || get(data, 'value.value_m_f2') || this.i18n('premises');
    const header = get(projectData, 'display_name');
    const alt = `${typeObject} в ${header}`;

    return (
      <>
        {this.renderThumb(image, alt)}
        {this.renderType(data, soldData)}
        {this.renderArea(data, true)}
        {renderMainPrice.call(this, data, bemClasses, true, isSold)}
        {this.renderDiapasonPrice(data, isSold)}
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'ImageObject',
            name: alt || null,
            description: alt || null,
            contentLocation: get(projectData, 'parent_display_path', null),
            contentUrl: image ? absoluteUrl(`/images/thumb/full/${image}`) : null
          }}
        />
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'Apartment',
            name: apartmentName,
            url: absoluteUrl(url),
            image: absoluteUrl(`/images/thumb/full/${image}`),
            numberOfRooms: (type === 'living_complex' && groupId === 'flats') ? {
              '@type': 'QuantitativeValue',
              value: apartmentValue,
              unitCode: apartmentType
            } : null,
            floorSize: {
              '@type': 'QuantitativeValue',
              maxValue: maxArea,
              minValue: minArea,
              value: areaTxt,
              unitCode: areaType
            }
          }}
        />
      </>
    );
  }

  renderFavouriteButton() {
    const { object, favData } = this.props;
    const index = findIndex(favData, { entity_id: object.id });

    return (
      <Col {...bemClasses('col', 'favourite')} allAuto allPr={0}>
        <InteractiveComponent
          {...bemClasses('favorite')}
          iconType={iconTypes.favoritesBorder}
          newIconType={iconTypes.favorites}
          destinationPoint="favoritesIcon"
        >
          <FavoritesButton
            project="domik"
            entity="object"
            entityId={object.id}
            subgroup={object.$entity}
            inFavorites={index > -1}
            counterProps={{
              action: "favoritesObjectClick",
              entity:{
                project_id: get(this, 'props.projectData.id'),
                object_id: object.id,
              }
            }}
            hideLabel
          />
        </InteractiveComponent>
      </Col>
    );
  }

  renderLink =(data = {}, type)=> {
    const { price, url } = data;
    const all_area = data.object_properties && data.object_properties.all_area;
    const title = {};

    if (price && all_area && price.mainPrice) {
      title.value =
      `${humanizeBigNumbers.call(this, Math.round(price.mainPrice))}\u00a0${lowerCase(price.currency)}\u0020за\u00a0${all_area}\u00a0м²`;
    } else if (!type) {
      title.value = this.i18n('defaultPriceText');
    }

    return (
      <Link
        {...bemClasses('link', 'fill')}
        href={url}
        title={title.value}
      >
        <span />
      </Link>
    );
  };

  renderThumb(image, alt = '') {
    return (
      <Col {...bemClasses('col', 'img')} allAuto allPr={0}>
        <div {...bemClasses('imgContainer')}>
          <div {...bemClasses('img')}>
            {image &&
              <Thumb
                {...bemClasses('imgPic')}
                image={{ id: image, alt }}
              />
            }
          </div>
        </div>
      </Col>
    );
  }

  renderArea(data, isGroup = false) {
    const { minArea, maxArea, all_area, land_area, groupId } = data;
    const land_area_type = get(data, 'land_area_type') || {};
    let areaType = !all_area && land_area && land_area_type ?
      numDescOnlyText(
        land_area,
        land_area_type.F1 || '',
        land_area_type.F2 || '',
        land_area_type.F3 || ''
      ) :
      'кв.м';
    const landArea = land_area_type.id === 2 ? land_area / 100 : land_area;

    let areaDiapason = minArea ? `${Math.floor(minArea)}` : null;
    if (groupId === 'lands') {
      areaType = numDescOnlyText(Math.floor(minArea), 'сотка', 'сотки', 'соток');
    }
    if (maxArea && Math.floor(maxArea) !== Math.floor(minArea)) {
      areaDiapason = `${areaDiapason}...${Math.floor(maxArea)}`;
      if (groupId === 'lands') {
        areaType = numDescOnlyText(Math.floor(maxArea), 'сотки', 'соток', 'соток');
      }
    }

    const areaTxt = areaDiapason || all_area || landArea;

    return (
      <Col {...bemClasses('col', 'area')} allPr={0}>
        {areaTxt &&
          <span {...bemClasses('text', 'area')}>{areaTxt}&nbsp;{areaType}</span>
        }
      </Col>
    );
  }

  renderDiapasonPrice = (data, isSold) => {
    const { i18n } = this;
    const { minPriceM, maxPriceM, priceCur = {} } = data;
    const { name_short } = priceCur;
    const price = {};

    if (name_short) {
      const currency = name_short;

      price.diapason = `${beautifyNumber(minPriceM)}`;
      if (maxPriceM && maxPriceM !== minPriceM) {
        price.diapason = `${price.diapason} — ${beautifyNumber(maxPriceM)}`;
      }
      price.diapason = `${price.diapason}\u00a0${currency}/м²`;
      // price.mainPrice = `${beautifyNumber(maxPriceM)}\u00a0${currency}/м²`;
    }
    if (!minPriceM)  price.diapason = null;
    if (!price.diapason && isSold)  price.diapason = i18n('sold');

    return (
      <Col {...bemClasses('col', 'price priceFromTo')} allPr={0} mt={0} mp={0}>
        {!!price.diapason &&
          <span {...bemClasses('text', 'price')}>{price.diapason}</span>
        }
      </Col>
    );
  }

  renderAltPrice = (altPrice, isSold) => {
    const { i18n } = this;
    let price = altPrice;
    if (!altPrice && isSold)  price = i18n('sold');

    return (
      <Col {...bemClasses('col', 'priceMin')} allPr={0}>
        <span {...bemClasses('text', 'priceMin')} children={price} />
      </Col>
    );
  }

  /*
  renderSoldOrCount(soldData, all_flats_sold_object, flats_count, $entity) {
    const sold = checkSold.call(this, all_flats_sold_object, soldData, $entity);
    let txt = <span {...bemClasses('text', 'sold')} children={sold.soldTxt} />;
    const number = flats_count || 1;

    if (!sold.sold) {
      const countText = `${number} шт.`;

      txt = (
        <span
          {...bemClasses('text', 'countFlats')}
          children={countText}
        />
      );
    }

    return <Col {...bemClasses('col', 'sold')} allPr={0} children={txt} />;
  }
  */

  renderType(data = {}/* , soldData = {} */) {
    const { value = {} } = data;
    // const { count, group, value = {}, allSold } = data;
    // const sold = checkSold.call(this, allSold, soldData, group);

    const name = value.value_long || value.entity_long;
    // const countText = `${count} шт.`;
    const txt = (
      <div>
        {name && <span {...bemClasses('text', 'type')} children={name} />}
        {/*
        {sold.sold && <span {...bemClasses('text', 'sold')} children={sold.soldTxt} />}
        {!sold.sold && <span {...bemClasses('text', 'count')} children={countText} />}
        */}
      </div>
    );

    return <Col {...bemClasses('col', 'type')} allPr={0} children={txt} />;
  }

  render() {
    const { className, dataMods, type, object, soldData, permObjManage } = this.props;
    const { data = {}, all_flats_sold_object, $entity } = object;
    const { group, allSold } = data;
    const groupType = group || $entity;
    const sold = checkSold.call(this, all_flats_sold_object || allSold, soldData, groupType);
    const dataModsListCard = (type === 'living_complex') ?
      { ...dataMods, plansGroup: true, sold: sold.sold } :
      { ...dataMods, sold: sold.sold };

    const renderItemsListCard = (type === 'living_complex') ? this.listForComplex(sold.sold) : this.listForObjects(sold.sold);
    // const permObjManage = checkPermission(perms.OBJECT_MANAGE_ALL_NEWBUILD_OBJECTS);

    return (
      <>
        <div {...bemClasses(null, dataModsListCard, className)}>
          {this.renderLink(object, type)}
          <Row {...bemClasses('content')} alignIcenter children={renderItemsListCard} />
        </div>
        {permObjManage &&
          <div {...bemClasses('link', 'toObject')}>
            <Link
              builder={linksBackOffice.pageNewbuildObjects}
              query={{
                id: object.id,
                entity: 'object',
                date_life: object.date_life,
                type: object.type_eng
              }}
              noRedirector
              external
              blank
            >
              <span children="Изменить" />
            </Link>
          </div>
        }
      </>
    );
  }
}

export function renderMainPrice(
  data, bemClass, isGroup = false, isSold = false, toJson = false
) {
  const { i18n } = this;
  const { minPrice, maxPrice, priceCur = {} } = data;
  const { name_short } = priceCur;
  let price = { mainPrice: minPrice || 0, currency: name_short };

  if (data.mainPrice) price = data;

  let mainPrice = i18n('defaultPriceText');

  if (price && price.mainPrice && price.currency) {
    const currency = price.currency;
    const groupPrefix = isGroup && minPrice !== maxPrice ? `${i18n('textFrom')}\u00a0` : '';
    mainPrice = `${groupPrefix}${humanizeBigNumbers.call(this,  Math.round(price.mainPrice))} ${currency}`;
  }

  const seoPriceCurrency = get(data,'name_standart') || get(data, 'priceCur.name_standart');
  const seoPrice = data.mainPrice || minPrice || mainPrice;
  const seoJsonContent = typeof seoPrice === 'number' ? {
    '@type': 'Offer',
    price: seoPrice,
    priceCurrency: seoPriceCurrency,
    availability: isSold ? 'https://schema.org/SoldOut' : 'https://schema.org/InStock'
  } : null;
  const seoJson = (
    <JsonLd
      item={{
        '@context': 'https://schema.org',
        ...seoJsonContent
      }}
    />
  );

  if (toJson) return seoJsonContent;

  return (
    <Col {...bemClass('col', 'price')} allPr={0}>
      <span {...bemClass('text', 'price')}>{mainPrice}</span>
      {seoJson}
    </Col>
  );
}

export function renderPricePerMeter(data = {}, bemClass, noTags = false) {
  const { altPrice, currency, altClassName } = data;
  if (!altPrice || !currency) return null;
  let mainPrice = `${beautifyNumber(altPrice)}\u00a0${currency}${altClassName}`;

  if (noTags) {
    mainPrice = `${beautifyNumber(altPrice)} ${currency.toLowerCase()}${altClassName}`;
    return mainPrice;
  }

  return (
    <Col {...bemClass('col', 'price')} allPr={0}>
      <span {...bemClass('text', 'price')} children={mainPrice} />
    </Col>
  );
}

export function checkSold(allSold, soldData, group, item) {
  const { i18n } = this;
  let soldTxt = null;
  let sold = allSold;
  if (!soldData) return { sold, soldTxt };
  if (
    group === 'flats' ||
    group === 'objectsSaleFlatNewbuild' ||
    group === 'living_complex' ||
    group === 'newbuilds'
  ) {
    soldTxt = !item ? i18n('allFlatsSoldTile') : i18n('allFlatsSoldItem');
    if (!sold) sold = soldData.all_flats_sold;
  }
  if (
    group === 'commercial' ||
    group === 'objectsSaleMarketplace' ||
    group === 'objectsSaleOffice' ||
    group === 'objectsSaleFreePurpose'
  ) {
    soldTxt = !item ? i18n('allCommercialSoldTile') : i18n('allCommercialSoldItem');
    if (!sold) sold = soldData.all_commercial_sold;
  }
  if (
    group === 'cottageTowns' ||
    group === 'objectsSaleTownhouse' ||
    group === 'objectsSaleLandLiving' ||
    group === 'objectsSaleHouse'
  ) {
    soldTxt = !item ? i18n('allHouseAndAreaTile') : i18n('allHouseAndAreaItem');
    if (!sold) sold = soldData.all_house_and_area_sold;
  }

  return { sold, soldTxt };
}
