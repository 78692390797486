// import searchTranslates from '../section-projects/search-i18n';
import configLabelsTranslates from '../section-project/config-labels-i18n';

export default function () {
  return {
    // ...searchTranslates(),
    ...configLabelsTranslates,

    developerTopic: {
      ru: 'Застройщик',
      ua: 'Забудовник'
    },
    partnersProject: {
      ru: 'Партнеры проекта',
      ua: 'Партнери проєкту'
    },
    developerYear: {
      ru: ' с\u00a0{{placeholder}}\u00a0года',
      ua: ' з\u00a0{{placeholder}}\u00a0року'
    },
    developerHouse: {
      ru: 'дом',
      ua: 'будинок'
    },
    developerHouseDeclination: {
      ru: 'дома',
      ua: 'будинки'
    },
    developerHouseMultiple: {
      ru: 'домов',
      ua: 'будинків'
    },
    developerBuild: {
      ru: 'строят',
      ua: 'будують'
    },
    developerInCount: {
      ru: 'в\u00a0{{placeholder}}\u00a0ЖК',
      ua: 'в\u00a0{{placeholder}}\u00a0ЖК'
    },
    developerHouseReady: {
      ru: 'готовый дом',
      ua: 'готовий будинок'
    },
    developerHouseReadyDeclination: {
      ru: 'готовых дома',
      ua: 'готових будинки'
    },
    developerHouseReadyMultiple: {
      ru: 'готовых домов',
      ua: 'готових будинків'
    },
    developerHouseReadyCT: {
      ru: 'готовый КП',
      ua: 'готове КМ'
    },
    developerHouseReadyCTDeclination: {
      ru: 'готовых КП',
      ua: 'готових КМ'
    },
    developerHouseReadyCTMultiple: {
      ru: 'готовых КП',
      ua: 'готових КМ'
    },
    developerLinkText: {
      ru: 'Сайт компании',
      ua: 'Сайт компанії'
    },

    moreDetails: {
      ru: 'Подробнее',
      ua: 'Детальніше'
    },
    hide: {
      ru: 'Скрыть',
      ua: 'Сховати'
    },

    developerH2: {
      ru: 'Новостройки от застройщика',
      ua: 'Новобудови від забудовника'
    },
    developerLand: {
      ru: 'Вся Украина',
      ua: 'Вся Україна'
    },
    developerFilterAllProjects: {
      ru: 'Все проекты',
      ua: 'Всі проєкти'
    },
    developerImgAlt: {
      ru: 'Жилые комплексы {{placeholder}}',
      ua: 'Житлові комплекси {{placeholder}}'
    },
    developerLinkToSiteTitle: {
      ru: 'Сайт застройщика',
      ua: 'Сайт забудовника'
    },
    developerFoundedYearText: {
      ru: 'год основания',
      ua: 'рік заснування'
    },
    developerHouseBuilt: {
      ru: 'дом строится',
      ua: 'будинок будується'
    },
    developerHouseBuiltDeclination: {
      ru: 'дома строятся',
      ua: 'будинки будуються'
    },
    developerHouseBuiltMultiple: {
      ru: 'домов строятся',
      ua: 'будинків будуються'
    },

    mapTopic: {
      ru: 'Расположение главного офиса',
      ua: 'Розташування головного офісу'
    },
    addressLabel: {
      ru: 'Адрес',
      ua: 'Адреса'
    },
    call: {
      ru: 'Позвонить',
      ua: 'Дзвонити'
    },

    btnToAskQuestionText: {
      ru: 'Задать вопрос',
      ua: 'Задати питання'
    },
    askQuestionTopic: {
      ru: 'Задайте вопрос застройщику',
      ua: 'Задайте питання забудовнику'
    },
    askQuestionMessage: {
      ru: 'Спасибо, ваш вопрос отправлен.',
      ua: 'Дякуємо, ваше запитання відправлено.'
    },
    developerForum: {
      ru: 'Форум {{placeholder}}',
      ua: 'Форум {{placeholder}}'
    }
  };
}
